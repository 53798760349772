.app-WebpageTouchable-icon-facebook {
  color: #4267B2; /* Facebook theme color */
  font-size: 24px;
}

.app-WebpageTouchable-icon-instagram {
  color: #000;
  font-size: 24px;
}

.app-WebpageTouchable-icon-twitter {
  color: #1DA1F2; /* Twitter theme color */
  font-size: 24px;
}

.app-WebpageTouchable-icon-youtube {
  color: #F00; /* Youtube theme color */
  font-size: 24px;
}
