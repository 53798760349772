.root {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: .25rem;
}

.iconContainer {
  min-width: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  flex: 1;
  min-height: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
