.app-WhatsappTouchable-iconContainer {
  background-color: #48E676; /* Whatsapp theme color */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.app-WhatsappTouchable-icon {
  color: #FFF;
  font-size: 20px;
}
