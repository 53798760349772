.app-AbstractTouchable-iconContainer {
  width: 40px;
  height: 40px;
}

.app-AbstractTouchable-anchor:hover .border {
  border-color: #333 !important;
  background-color: #F0F0F0;
}

.app-AbstractTouchable-hint {
  font-size: smaller;
}
