.link {
  width: 56px;
  height: 56px;
  border-radius: 50%;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  border: none;

  background-color: #ffea00;
  font-size: 0.875rem;
  line-height: 1.75;

  outline: 0;
  text-decoration: none;
  user-select: none;
}

.link,
.link:focus,
.link:hover {
  color: #000;
}

.link:hover {
  background-color: #E6DA51;
}

.link.extended {
  width: auto;
  height: 48px;
  padding: 0 16px;
  min-width: 48px;
  min-height: auto;
  border-radius: 8px;
}
