.providerBadge {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
  width: 100%;
}

.providerBadge img {
  width: 100%;
}

.providerBadgePlaceholder {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  min-height: 80px;
  padding-left: 3rem;
  padding-right: 3rem;
}

.deviceReq {
  font-weight: bold;
  margin-top: .75rem;
}

.footnote {
  font-size: small;
}

@media screen and (max-width: 991px) {
  .providerBadgePlaceholder {
    min-height: 60px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
