body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(242,242,242); */
}

body {
  background: linear-gradient(180deg, rgb(175, 162, 192) 0%, rgba(242,242,242,1) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ***** */

.app-line-breakable {
    word-break: break-word;
    white-space: pre-wrap;
}

/* ***** */

.navbar .app-name {
  font-size: 1.2rem;
}

.navbar .app-caption {
  font-size: .9rem;
}

/* ***** */

#header .appName {
  font-size: 1.4rem;
}

#header .appCaption {
  font-size: 1.2rem;
}

/* ***** */

#privacy {
  /* padding: 0 20%; */
  font-size: 14px;
}
#privacy h1 {
  font-size: 18px;
  font-weight: 700;
}
#privacy h2 {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
}
#privacy p {
  margin-top: 10px;
}

@media screen and (max-width: 991px) {
  #privacy {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  #brand .appName {
    font-size: 1.4rem;
  }

  #brand .appCaption {
    font-size: 1.2rem;
  }

  #header .appName {
    font-size: 1.4rem;
  }

  #header .appCaption {
    font-size: 1rem;
  }
}
