.root {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  border-bottom: #808080 solid 1px;
  padding-bottom: 3px;
  margin-bottom: 3px;
}

.rowIndex {
  width: 45px;
  text-align: center;
}

.upcomingSessionTextStyles {
  font-weight: bold;
}
